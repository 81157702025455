import React, { useState } from "react"
import { PrimaryBtn } from "components/library"
import { Carousel } from 'react-responsive-carousel'
import { StaticImage } from "gatsby-plugin-image"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"
import { TranslationKey } from "types/translation"

const mainSlideTitles: TranslationKey[] = [
	"common.words.project-management",
	"common.words.data-management",
	"common.words.data-analysis",
]

export const RndPageHero = () => {
	const [slide, setslide] = useState(0)
	const { t } = useTranslation()

	return (
		<section className="grid grid-cols-1 sm:grid-cols-2 gap-10 items-center">
			<div className="flex flex-col gap-8">
				<div className="flex flex-col gap-2">
					<span className="text-4xl tracking-wider sm:text-5xl md:text-7xl gradient-text">
						{t("rnd-page.hero.heading")}
					</span>
					<p className="sm:text-lg md:text-2xl">
						{t("rnd-page.hero.description")}
					</p>
				</div>

				<PrimaryBtn
					className="md:text-lg max-w-max"
					onClick={() => window.requestADemo()}
				>
					{t("common.cta.demo")}
				</PrimaryBtn>
			</div>

			<div className="text-center flex flex-col gap-4">
				<Carousel
					showArrows={false}
					showStatus={false}
					infiniteLoop={true}
					interval={7000}
					autoPlay={true}
					onChange={setslide}
					selectedItem={slide}
					className="rounded-3xl bg-gradient-box bg-cover"
				>
					<div className="p-6 pb-10">
						<StaticImage
							alt="illustration"
							src="../../../../images/illus_solutions_proj_management.png"
							className="bg-white shadow rounded-3xl"
						/>
					</div>
					<div className="p-6 pb-10">
						<StaticImage
							alt="illustration"
							src="../../../../images/illus_solutions_data_management.png"
							className="bg-white shadow rounded-3xl"
						/>
					</div>
					<div className="p-6 pb-10">
						<StaticImage
							alt="illustration"
							src="../../../../images/illus_solutions_data_analysis.png"
							className="bg-white shadow rounded-3xl"
						/>
					</div>
				</Carousel>
				<span>
					<Trans i18nKey={`${mainSlideTitles[slide % 3]}`} />
				</span>
			</div>
		</section>
	)
}
